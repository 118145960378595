import { isVideoLabel, isAudioLabel } from '../tools/LabelTools';
import { getCurrentProject } from './projectsSelectors';

/**
 * DATAKEYS STATE SELECTORS
 */

/**
 * Get the labels ARRAY of the current project
 * WARNING : returns all the labels EXCEPT THE AUDIO ONES !!
 *
 * @param {*} state The State
 */
export const getCurrentLabels = state => state.labels.labels;
export const getVideoLabels = (state) => {
  const labels = [];
  for (let i = 0; i < state.labels.labels.length; i += 1) {
    if (isVideoLabel(state.labels.labels[i], getCurrentProject(state))) {
      labels.push(state.labels.labels[i]);
    }
  }
  return labels;
};
export const getAudioLabels = (state) => {
  const labels = [];
  for (let i = 0; i < state.labels.labels.length; i += 1) {
    if (isAudioLabel(state.labels.labels[i], getCurrentProject(state))) {
      labels.push(state.labels.labels[i]);
    }
  }
  return labels;
};

/**
 * Get a SELECTOR that will allow to get a label given its IRI
 * @param {*} state The State
 */
export const getLabelFromIRISelector = (state) => {
  return (iri) => {
    const index = state.labels.labelsIndexesMap[iri];
    return state.labels.labels[index];
  };
};
export const getLabelFromIRI = (state, iri) => {
  const func = getLabelFromIRISelector(state);
  return func(iri);
};

/**
 * Get the status of labels state of the current project
 * @param {*} state The State
 */
export const getLabelsStatus = (state) => state.labels.status;

/**
 * Get the error of labels state of the current project
 * @param {*} state The State
 */
export const getLabelsError = (state) => state.labels.error;


export const getLabelByName = (state, labelName) => {
  const func = getLabelByNameSelector(state);
  return func(labelName);
};

export const getLabelByNameSelector = (state) => {
  return (labelName) => {
    let label = null;

    for (let i = 0; (i < state.labels.labels.length) && (label === null); i += 1) {
      if (state.labels.labels[i].name === labelName) {
        label = state.labels.labels[i];
      }
    }

    return label;
  };
};
