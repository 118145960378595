// import { UAParser } from 'ua-parser-js';
import CONSTANTS from '../Constants';

export const isNumber = (value) => isFinite(parseFloat(value));

/**
 * Calcul un pourcentage avec 2 chiffres apres la virgule
 * @param {*} number
 * @param {*} total
 */
export const calcPercent = (number, total) => {
  if (isNumber(number) && isNumber(total)) {
    const percent = (number / total) * 100;
    let result = 0;

    if (percent > 100) {
      return 100;
    }
    if (percent < 0) {
      return 0;
    }

    if (percent <= 1) {
      result = Number.parseFloat(percent).toPrecision(2);
    } else if (percent <= 10) {
      result = Number.parseFloat(percent).toPrecision(3);
    } else {
      result = Number.parseFloat(percent).toPrecision(4);
    }

    return result;
  }
  return 0;
};

/**
 * Return true if the name of the navigator if is same as the param
 * If no param return the name of the navigator
 * @param {*} string
 */
// Keep getNavigator code, just in case. But comment it as it is not used now (21/02/2020)
// export const getNavigator = (searchedNavigator) => {
//   const currentNavigator = UAParser(navigator.userAgent);
//   if (searchedNavigator) {
//     if (searchedNavigator === currentNavigator.browser.name) {
//       return true;
//     }
//     return false;
//   }
//   return currentNavigator.browser.name;
// };

// ------------------------------------------ CALC MEDIA TIME
export function formatTimeToDisplay(currentTime, duration) {
  if (currentTime && duration) {
    return (
      `${addingZero(Math.trunc((convertTime(currentTime, duration)) / 60))
      }:${
        addingZero(Math.trunc((convertTime(currentTime, duration)) % 60))}`
    );
  }

  return null;
}


function addingZero(timeToConvert) {
  if (timeToConvert || timeToConvert === 0) {
    let srTime = timeToConvert.toString();

    if (srTime.toString().length <= 1) {
      srTime = `0${srTime}`;
    }

    return srTime;
  }

  return null;
}

function convertTime(currentTime, duration) {
  // First we check if we have a number as param
  if (isNumber(currentTime) && isNumber(duration)) {
    // if params are number
    let timeToConvert = 0;
    const checkedTime = 0;

    if (checkedTime) {
      timeToConvert = checkedTime;
    } else {
      timeToConvert = currentTime;
    }

    timeToConvert = duration - timeToConvert;

    return timeToConvert;
  }
  return 0;
}
// ---------------------- END -------------------- CALC MEDIA TIME


/**
 * Indicates if the given coordinate is a valid one or not
 * @param {*} coord
 */
export function isValidCoordinate(coord) {
  return ((coord !== null) && (coord !== CONSTANTS.INVALID_COORDINATE));
}

/**
 * Indicates if the given position (with x and y fields!!) is a valid one or not
 * @param {} pos
 */
export function isValidPosition(pos) {
  return (isValidCoordinate(pos.x) && isValidCoordinate(pos.y));
}
